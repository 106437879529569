.over {
  transform: scale(1.1, 1.1);
  background-color: rgba($color: rgb(0, 0, 0), $alpha: 0.5);
  transition: all 0.3s ease;
}

.items {
  min-width: 150px;
  padding: 10px;
  border: 1px solid #ccc;
  transition: all 0.3s ease;
  list-style: none;
  position: relative;

  &:hover::after {
    opacity: 1;
    content: "drag";
    position: absolute;
    width: 100%;
    display: inline-flex;
    right: 0px;
    justify-content: flex-end;
    padding-right: 10px;
    color: aliceblue;
    font-size: 0.8em;
    animation-name: slideInRight;
    animation-duration: 0.2s;
    animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    right: 50px;
  }
  to {
    opacity: 1;
    right: 0px;
  }
}
