.gymowner-gymdetail-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;

  button {
    margin-bottom: 8px;
    text-transform: uppercase;
    font-size: 0.9em;
    letter-spacing: 0.05em;
  }

  .loadSpinner {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 95%;
    pointer-events: none;
    z-index: 100000;

    &.black {
      background-color: #00000070;
    }
  }

  .trade-license-message {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 96%;
    position: absolute;
    background-color: #000000be;
    text-align: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    min-width: 250px;
    color: #888;

    .form-group {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding: 0px;
      margin: 0px;
      gap: 8px;

      .form-row {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 50%;
      }
    }
  }

  .docs {
    display: flex;
    width: 420px;
    min-width: 420px;
    border-top: 1px solid #88888830;
    background-color: rgba($color: #000, $alpha: 0.5);
    color: #888;
    font-size: 0.8em;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    height: max-content;

    .tradelicense-container {
      position: relative;
      display: flex;
      flex-direction: column;
      flex: content;
      justify-content: center;
      align-items: center;
      gap: 5px 5px;
      border-bottom: 1px solid #88888840;
      box-shadow: 0px 1px 0px 0px #00000050;
      padding-bottom: 15px;
      margin-bottom: 15px;

      img {
        border: 1px solid #88888830;
        background-color: #88888820;
        width: 250px;
        min-height: 330px;
        height: max-content;
        object-fit: scale-down;
        margin-top: 8px;
        margin-bottom: 8px;
        border-radius: 8px;
        cursor: pointer;
      }

      button {
        margin-bottom: 8px;
        text-transform: uppercase;
        font-size: 1em;
        letter-spacing: 0.05em;
      }
    }

    .membershipcard-container {
      position: relative;
      display: flex;
      flex-direction: column;
      flex: content;
      justify-content: center;
      align-items: center;
      gap: 5px 5px;
      padding-top: 15px;
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-radius: 8px;
      width: 350px;
      min-height: 230px;
      height: 230px;
      max-height: 230px;

      .user-image-thumb {
        position: absolute;
        display: flex;
        width: 75px;
        height: 75px;
        background-color: rgba($color: #fff, $alpha: 1);
        border: 1px solid rgba($color: #000, $alpha: 0.5);
        right: 25px;
        top: 10px;
        justify-content: center;
        align-items: center;
        font-size: 4em;
        color: #88888850;
        border-radius: 5px;
      }

      img {
        border: 1px solid #88888830;
        background-color: #88888820;
        width: 350px;
        min-height: 230px;
        height: 230px;
        max-height: 230px;
        object-fit: cover;
        margin-top: 8px;
        margin-bottom: 8px;
        border-radius: 8px;
      }

      button {
        margin-bottom: 8px;
        text-transform: uppercase;
        font-size: 1em;
        letter-spacing: 0.05em;
      }
    }
  }

  .attachment-section-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    button {
      background: none;
      border: none;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #e2c02b;
      padding: 5px 0px;

      svg {
        margin-top: -5px;
        margin-right: 5px;
      }

      &:focus {
        box-shadow: none;
        border: none;
        outline: none;
      }
    }
  }

  .gym-attachment-table {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    margin: 15px 0px;
    .table-body {
      display: flex;
      width: 100%;
      margin: 0;
      padding: 0;
      flex-direction: column;
      & .table-row {
        display: flex;
        width: 100%;
        justify-content: space-between;

        & > span {
          display: flex;
          flex-grow: 1;
          width: 100%;
          border-bottom: 1px solid #88888850;
          font-size: 0.9em;
          color: #888;
          padding-left: 5px;
          overflow-x: hidden;
          align-items: center;
          padding: 5px 0px;
          a {
            padding: 0;
            color: royalblue;
            text-decoration: underline;
            cursor: pointer;

            &:hover {
              color: goldenrod;
            }
          }
        }

        & span:first-child {
          display: inline-flex;
          width: 80px;
        }

        & span:last-child {
          display: inline-flex;
          justify-content: flex-end;
          width: unset;
          min-width: 50px;
          padding-right: 5px;

          button {
            border-radius: 50%;
            padding: 1px 5px;
            margin: 0;
          }
        }
      }
    }
    .table-headings {
      display: flex;
      width: 100%;
      justify-content: space-between;
      & > span {
        display: flex;
        flex-grow: 1;
        width: 100%;
        border-bottom: 1px solid #88888850;
        font-size: 0.9em;
        color: #888;
        padding-left: 5px;
        overflow-x: hidden;
        align-items: center;
        padding: 5px 0px;
        a {
          padding: 0;
        }
      }

      & span:first-child {
        display: inline-flex;
        width: 80px;
      }

      & span:last-child {
        display: inline-flex;
        justify-content: flex-end;
        width: unset;
        min-width: 50px;
        padding-right: 5px;

        button {
          border-radius: 50%;
          padding: 1px 5px;
          margin: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 1180px) {
  .gymowner-gymdetail-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .content {
      width: 100%;
      .form-group {
        flex-direction: column;
        .form-row {
          width: 100%;
        }
      }
    }
    .docs {
      margin-top: 35px;
      width: 100%;
    }
  }
}

@media screen and (max-width: 1260px) {
  .gymowner-gymdetail-container {
    .content {
      width: 100%;
      .form-group {
        flex-direction: column;
        .form-row {
          width: 100%;
        }
      }
    }
  }
}

.add-attachment-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 350px;

  button {
    text-transform: uppercase;
    font-size: 0.9em;
    letter-spacing: 0.05em;
  }

  .attachment-file-uploader {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 42px;
    border: 1px solid #88888850;
    color: #fff;
    padding: 5px 15px;
    overflow-x: hidden;

    .delete-btn {
      width: max-content !important;
    }

    .error-msg {
      display: block;
      flex: 1;
      width: max-content;
      margin-bottom: 12px;
      margin-top: 8px;
      padding: 0px 10px;
      text-align: center;
      color: rgb(230, 68, 68);
      background-color: rgb(83, 26, 26);
      border-radius: 4px;
      font-size: 0.9em;
    }

    .file-name {
      display: block;
      flex: 1;
      width: 100%;
      margin-bottom: 12px;
      margin-top: 8px;
      text-align: center;
      color: #888;
      min-height: 32px;
    }

    button {
      width: 95%;
    }
  }
}
