.content-policy {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0;
  margin-top: 30vh;
  align-items: center;
  .policy{
    display: flex;
    width: 100%;
    flex-direction: column;
    max-width: 100vh;
    align-items: center;
    .title {
      color: #feca00;
    }
    p{
      color: white;
    }
    a{
      color: #feca00;
      text-decoration: none;
    }
  }
  .terms{
    display: flex;
    width: 100%;
    flex-direction: column;
    max-width: 100vh;
    align-items: center;
    .title {
      color: #feca00;
    }
    p{
      color: white;
    }
    a{
      color: #feca00;
      text-decoration: none;
    }
  }
}
