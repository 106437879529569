@mixin scrollbars {
  &::-webkit-scrollbar {
    width: 3px;
    position: absolute;
    cursor: pointer;
  }
  &::-webkit-scrollbar-track {
    background: #88888850;
    padding: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgb(197, 169, 9);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgb(158, 136, 8);
    cursor: pointer;
  }
}

.item {
  animation-name: moveOly !important;
  animation-duration: 0.4s !important;
  animation-timing-function: ease !important;
}
.content-body-container {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  flex: 1;
  position: absolute;
  top: 70px;
  min-height: calc(100vh - 70px);
  max-height: calc(100vh - 70px);
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;

  .content-plan-container {
    position: relative;
    display: flex;
    padding: 20px;
    background-color: transparent;
    flex-direction: row;
    justify-content: center;
    flex-grow: 1;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 75%;
    min-width: 250px;
    max-width: 75%;
    gap: 10px 20px;
    min-height: max-content;
    overflow-y: auto;
    @include scrollbars();
  }

  .content-planlist-container {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: rgb(43, 41, 44);
    flex-grow: 1;
    min-width: 200px;
    color: #fff;

    .planlist-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .plan-list-draggable-container {
      width: 100%;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      background-color: transparent;
      height: calc(100% - 30px);
      list-style: none;
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 15px;
      overflow-y: auto;
      //transition: all 0.5s ease;
      @include scrollbars();

      & div {
        &.dl-item {
          margin: 5px 0px;
        }
      }

      & .dragged > li {
        opacity: 0.5;
      }

      & div > li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: rgba($color: #000000, $alpha: 0.2);
        margin-right: 15px;
        padding: 15px 15px;
        border: 0.05rem solid #88888840;
        border-radius: 5px 5px 5px 5px;
        position: relative;
        transition: all 0.3s ease;
        cursor: pointer;
        animation-name: fadeOly;
        animation-duration: 0.3s;
        animation-timing-function: ease;

        & .draggable-item-icon {
          display: block;
          padding: 1px;
          position: absolute;
          width: 0px;
          height: 98%;
          top: 0px;
          left: -1px;
          border-radius: 8px 0px 0px 8px;
          background-color: rgba($color: #fff, $alpha: 0.3);
        }

        & .priority-index {
          font-size: 0.8em;
          color: #888888;
        }

        &:hover {
          transform: scale(1.05);
          border-color: #88888870;
          background-color: #00000050;
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .content-body-container {
    .content-plan-container {
      justify-content: space-evenly;
    }
  }
}

@media screen and (max-width: 768px) {
  .content-body-container {
    padding: 5px;
    background-color: transparent;
    display: flex;
    flex-direction: column-reverse;
    flex: 1;

    .content-plan-container {
      width: auto;
      max-width: none;
      flex: 1;
      min-height: 90vh;
    }

    .content-planlist-container {
      width: auto;
      flex: 1;
    }
  }
}

@keyframes fadeOly {
  from {
    top: -50px;
  }
  to {
    top: 0px;
  }
}

@keyframes moveOly {
  from {
    opacity: 0 !important;
    //left: -50px !important;
    transform: scale(0.7);
  }
  to {
    opacity: 1 !important;
    //left: 0px !important;
    transform: scale(1);
  }
}
