.modal-backdrop {
  opacity: 0.8 !important;
}
.confirmDialog-modal {
  & .modal-dialog {

    // header
    & .modal-header {
      background-color: #000 !important;
      padding: 10px 15px;
      border: none;
      border-top: 1px solid rgba($color: #fff, $alpha: 0.2);
      border-left: 1px solid rgba($color: #fff, $alpha: 0.2);
      border-right: 1px solid rgba($color: #fff, $alpha: 0.2);
      box-shadow: 0px -20px 40px 10px rgba($color: rgb(56, 56, 56), $alpha: 0.2);
      border-radius: 8px 8px 0px 0px;

      & .modal-title {
        font-size: 1.2rem !important;
        font-weight: 500;
        color: #acacac;
      }

      & button {
        color: #b3b3b3;
        border: none;
        outline: none;
        background-color: transparent;
        font-size: 1.3rem;
        font-weight: 600;

        &:hover {
          color: #fbd806;
        }
      }
    }

    //body
    & .modal-body {
      background-color: #000 !important;
      border-left: 1px solid rgba($color: #fff, $alpha: 0.2);
      border-right: 1px solid rgba($color: #fff, $alpha: 0.2);
      color: #ddd;
      box-shadow: 0px 0px 40px 10px rgba($color: rgb(56, 56, 56), $alpha: 0.2);

      &.body-content-fullborder{
        border-bottom: 1px solid rgba($color: #fff, $alpha: 0.2);
        border-radius: 0px 0px 8px 8px;
        padding-bottom: 20px;
      }
    }

    // footer
    & .modal-footer {
      background-color: #000 !important;
      border : none;
      border-bottom: 1px solid rgba($color: #fff, $alpha: 0.2);
      border-left: 1px solid rgba($color: #fff, $alpha: 0.2);
      border-right: 1px solid rgba($color: #fff, $alpha: 0.2);
      padding: 10px 15px;
      box-shadow: 0px 20px 40px 10px rgba($color: rgb(56, 56, 56), $alpha: 0.2);
      border-radius: 0px 0px 8px 8px;
    }
  }
}
