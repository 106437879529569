$col-minWidth: 200px;
$col-maxWidth: auto;
$col-controlsWidth: 175px;
$col-controlsWidthMobile: 175px;
.page-content {
  display: flex;
  background-color: transparent;
  width: 100%;
  overflow: hidden;
  height: calc(100vh - 150px);
  justify-content: center;

  .grid-container {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin-top: 20px;
  }

  .reporting-action-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0px;

    & .reporting-action-title {
      font-size: 1em;
      font-weight: 600;
      color: #d3d3d3;
    }
    & > div {
      display: flex;
      flex-direction: row;

      & > div {
        margin: 0px 5px;
      }
    }

    .custom-select-report {
      min-width: 100px !important;

      & .css-mr873i-menu {
        border: 1px solid rgba($color: #fff, $alpha: 0.1);
        margin-top: 3px;
        & > * {
          &::-webkit-scrollbar {
            width: 3px;
            position: absolute;
            cursor: pointer;
          }
          &::-webkit-scrollbar-track {
            background: #88888850;
            padding: 5px;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #888;
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #555;
            cursor: pointer;
          }
        }
      }
    }
  }

  .content-container {
    height: 100%;
    overflow-y: auto;
    padding: 0px 10px;

    &::-webkit-scrollbar {
      width: 3px;
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #88888850;
      padding: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgb(214, 177, 12);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: rgb(170, 147, 44);
      cursor: pointer;
    }
  }

  .content-table {
    display: flex;
    flex-direction: column;
    padding: 5px 0px 15px 0px;

    .controls-group {
      display: none;
    }

    .table-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      line-height: 160%;
      letter-spacing: 0.01em;
      color: #888888;
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #4f4f4f;
      width: 100%;
      min-width: max-content;
      position: sticky;
      top: -8px;
      background-color: #1c1a1d;

      & > * {
        width: 100%;
        min-width: $col-minWidth;
        max-width: $col-maxWidth;
        padding-left: 5px;
      }
      .controls {
        width: 100%;
        min-width: $col-controlsWidth;
        max-width: $col-controlsWidth;
        text-align: end;
      }
    }

    .table-content {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      line-height: 160%;
      color: #d6d6d6;
      max-height: max-content;

      .table-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 1.1em;
        line-height: 360%;
        letter-spacing: 0.01em;
        color: #d3d3d3;
        padding: 0px 0px;
        border-bottom: 1px solid #4f4f4f;
        width: 100%;
        min-width: max-content;

        & > * {
          width: 100%;
          min-width: $col-minWidth;
          max-width: $col-maxWidth;
          overflow: hidden;
          padding-left: 5px;
        }

        .controls-spacer {
          display: none;
        }

        .controls {
          width: 100%;
          min-width: $col-controlsWidth;
          max-width: $col-controlsWidth;
          text-align: end;
          line-height: 1.5;

          .button-view {
            display: inline-flex;
            border: none;
            background-color: #333333;
            max-width: max-content;
            max-height: 30px;
            min-width: 30px;
            min-height: 30px;
            border-radius: 50px;
            padding: 0;
            margin: 0;
            color: #2f80ed;
            justify-content: center;
            align-items: center;
            padding: 5px 5px;
            margin-right: 15px;

            span {
              color: #fff;
              margin: 0px 10px;
            }

            &:hover {
              background-color: #1f2f46;
            }
          }
        }
      }

      .table-row:nth-child(even) {
        background-color: rgba($color: #fff, $alpha: 0.01);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .page-header-rightside {
    display: flex;
    flex-direction: column;
    width: 100%;
    .header-search-bar {
      display: flex;
      width: 100%;
      max-width: 224px;
      padding: 0px 20px;
      margin-top: 8px;
      margin-left: auto;
    }
  }
  .page-content {
    display: flex;
    width: 100%;
    height: max-content;
    padding: 0px !important;
    margin: 0px !important;
    justify-content: center;
    .grid-container {
      width: 90% !important;
      justify-content: center;
    }
    .content-container {
      padding: 0px 10px 15px 10px;
      border: 2px solid #888888;
      border-radius: 5px;
      height: 100%;
      overflow-y: auto;
    }
    .content-table {
      position: relative;
      .table-header {
        position: sticky;
        top: 0px;
        border-bottom: none;

        & > * {
          padding-bottom: 10px;
          border-bottom: 1px solid #4f4f4f;
          width: 100%;
          min-width: $col-minWidth;
          max-width: $col-maxWidth;
          overflow: hidden;
          padding-top: 10px;
        }

        .controls {
          display: flex;
          width: 100%;
          min-width: $col-controlsWidthMobile;
          max-width: 1$col-controlsWidthMobile;
          justify-content: space-around;
        }
      }

      .table-content {
        overflow: visible;
        .table-row {
          width: 100%;
          & > * {
            width: 100%;
            min-width: $col-minWidth;
            max-width: $col-maxWidth;
            overflow: hidden;
          }

          .controls {
            display: flex;
            width: 100%;
            min-width: $col-controlsWidthMobile;
            max-width: 1$col-controlsWidthMobile;
            justify-content: space-around;
          }
        }
      }
    }
  }
}

.minmax50 {
  min-width: 50px !important;
  max-width: 50px !important;
}
.minmax75 {
  min-width: 75px !important;
  max-width: 75px !important;
}
.minmax100 {
  min-width: 100px !important;
  max-width: 100px !important;
}
.minmax125 {
  min-width: 125px !important;
  max-width: 125px !important;
}
.minmax150 {
  min-width: 150px !important;
  max-width: 150px !important;
}
.minmax200 {
  min-width: 200px !important;
  max-width: 200px !important;
}
.minmax250 {
  min-width: 250px !important;
  max-width: 250px !important;
}
.minmax300 {
  min-width: 300px !important;
  max-width: 300px !important;
}
  @media (max-width: 1180px) {
    .page-section-container{
      margin-top: 86px;
    }
  }