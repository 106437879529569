.qrpanel {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 60px;
  background-color: #fff;
}
.qrmodalbox {
  max-width: 768px;
  height: 1000px;
  background-color: #fff;
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  border: 8px solid rgb(0, 0, 0);
}
.qrboxcontainer {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 15px;
}

.qrbox {
  border: 2px solid #000;
}

.qrboxcontainer h1 {
  padding-top: 40px;
  font-weight: bolder;
  font-size: 3rem;
}

.qrboxcontainer h2 {
  padding-bottom: 20px;
  font-weight: 900;
  font-size: 1.5rem;
  color: rgb(106, 108, 112);
}

.qrtoppad {
  display: flex;
  justify-content: center;
  width: 900px;
  margin-top: 100px;
  height: fit-content;
}
