.theme-button-bt {
  cursor: pointer;
  height: 42px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
  border-radius: 5px;
  text-transform: capitalize;
  color: #000000;
  background: #feca00;
  border: none;
  padding: 0px 15px;
  margin: 0px;

  &:hover {
    box-shadow: 0px 0px 1px 3px #feca0060;
  }

  &.bt-secondary {
    color: #000000;
    background: #dddddd;

    &:hover {
        box-shadow: 0px 0px 1px 3px #ffffff40;
      }
  }
}
