.panel-container {
    display: flex;
    flex-grow: 1;
    min-height: 50px;
    padding: 10px;
  
    & .pane-body {
      background-color: #000;
      display: flex;
      width: 100%;
      border-radius: 15px;
      color: #cacaca;
      padding: 5px 15px;
    }
  }

  @media (max-Width: 768px) {
    .panel-container{
      padding-bottom: 80px;
    }
  }