.page-section-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #1c1a1d;
  min-height: 100vh;

  height: 100%;
  max-height: 100vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 3px;
    position: absolute;
    cursor: pointer;
  }
  &::-webkit-scrollbar-track {
    background: #88888850;
    padding: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgb(204, 174, 4);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
  & .page-section-body {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    flex-direction: column;
    background-color: #1c1a1d;
    width: 100%;
    height: 100%;
  }

  &.autoHeight {
    min-height: max-content;
    max-height: max-content;
    overflow-y: visible;
  }
}
