.company-info-body {
  padding: 0px !important;
  margin: 0px !important;
}

.company-information-section-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: max-content;

  & .gym-form-container {
    display: flex !important;
    width: 100% !important;

    .form-label {
      display: block;
      font-size: 14px;
      color: #9c9c9c;
      margin: 0px;
      padding: 0px;
      margin-bottom: 3px;
      margin-top: -8px;
    }
  }

  .form-group {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 0px;
    margin: 0px;
    gap: 8px;

    .form-row {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 50%;
    }
  }
}

.membershipcard-container {
  position: relative;
  display: flex;
  width: 350px;
  height: max-content;
  flex-direction: column;

  .user-image-thumb {
    position: absolute;
    display: flex;
    width: 75px;
    height: 75px;
    background-color: rgba($color: #fff, $alpha: 1);
    border: 1px solid rgba($color: #000, $alpha: 0.5);
    right: 20px;
    top: 30px;
    justify-content: center;
    align-items: center;
    font-size: 4em;
    color: #88888850;
    border-radius: 5px;
  }

  img {
    border: 1px solid #88888830;
    background-color: #88888820;
    width: 350px;
    min-height: 230px;
    height: 230px;
    max-height: 230px;
    object-fit: cover;
    margin-top: 8px;
    margin-bottom: 8px;
    border-radius: 8px;
    cursor: pointer;
  }

  button {
    margin-bottom: 8px;
    text-transform: uppercase;
    font-size: 1em;
    letter-spacing: 0.05em;
  }
}

.loadSpinner {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 95%;
  pointer-events: none;
  z-index: 100000;

  &.black {
    background-color: #00000070;
  }
}

.tradelicense-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: content;
  justify-content: center;
  align-items: center;
  gap: 5px 5px;
  box-shadow: 0px 1px 0px 0px #00000050;
  padding-bottom: 15px;
  margin-bottom: 15px;

  img {
    border: 1px solid #88888830;
    background-color: #88888820;
    width: 250px;
    min-height: 330px;
    height: max-content;
    object-fit: scale-down;
    margin-top: 8px;
    margin-bottom: 8px;
    border-radius: 8px;
  }

  button {
    margin-bottom: 8px;
    text-transform: uppercase;
    font-size: 1em;
    letter-spacing: 0.05em;
  }
}

.add-attachment-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 350px;

  button {
    text-transform: uppercase;
    font-size: 0.9em;
    letter-spacing: 0.05em;
  }

  .attachment-file-uploader {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 42px;
    border: 1px solid #88888850;
    color: #fff;
    padding: 5px 15px;
    overflow-x: hidden;

    .delete-btn {
      width: max-content !important;
    }

    .error-msg {
      display: block;
      flex: 1;
      width: max-content;
      margin-bottom: 12px;
      margin-top: 8px;
      padding: 0px 10px;
      text-align: center;
      color: rgb(230, 68, 68);
      background-color: rgb(83, 26, 26);
      border-radius: 4px;
      font-size: 0.9em;
    }

    .file-name {
      display: block;
      flex: 1;
      width: 100%;
      margin-bottom: 12px;
      margin-top: 8px;
      text-align: center;
      color: #888;
      min-height: 32px;
    }

    button {
      width: 95%;
    }
  }
}

.attachment-section-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  button {
    background: none;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #e2c02b;
    padding: 5px 0px;

    svg {
      margin-top: -5px;
      margin-right: 5px;
    }

    &:focus {
      box-shadow: none;
      border: none;
      outline: none;
    }
  }
}

.gym-attachment-table {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin: 15px 0px;
  .table-body {
    display: flex;
    width: 100%;
    margin: 0;
    padding: 0;
    flex-direction: column;
    & .table-row {
      display: flex;
      width: 100%;
      justify-content: space-between;

      & > span {
        display: flex;
        flex-grow: 1;
        width: 100%;
        border-bottom: 1px solid #88888850;
        font-size: 0.9em;
        color: #888;
        padding-left: 5px;
        overflow-x: hidden;
        align-items: center;
        padding: 5px 0px;
        a {
          padding: 0;
          color: royalblue;
          text-decoration: underline;
          cursor: pointer;

          &:hover {
            color: goldenrod;
          }
        }
      }

      & span:first-child {
        display: inline-flex;
        width: 80px;
      }

      & span:last-child {
        display: inline-flex;
        justify-content: flex-end;
        width: unset;
        min-width: 50px;
        padding-right: 5px;

        button {
          border-radius: 50%;
          padding: 1px 5px;
          margin: 0;
        }
      }
    }
  }
  .table-headings {
    display: flex;
    width: 100%;
    justify-content: space-between;
    & > span {
      display: flex;
      flex-grow: 1;
      width: 100%;
      border-bottom: 1px solid #88888850;
      font-size: 0.9em;
      color: #888;
      padding-left: 5px;
      overflow-x: hidden;
      align-items: center;
      padding: 5px 0px;
      a {
        padding: 0;
      }
    }

    & span:first-child {
      display: inline-flex;
      width: 80px;
    }

    & span:last-child {
      display: inline-flex;
      justify-content: flex-end;
      width: unset;
      min-width: 50px;
      padding-right: 5px;

      button {
        border-radius: 50%;
        padding: 1px 5px;
        margin: 0;
      }
    }
  }
}

.select-error {
  border: 1px solid rgb(230, 68, 68);
}

@media screen and (max-width: 768px) {
  .gym-form-container {
    .form-group {
      flex-direction: column;
      justify-content: center;
      .form-row {
        width: 100%;
      }
    }
  }
}
