.user-detail-page-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: transparent;
}

.user-image-container {
  display: flex;
  width: 175px;
  height: 175px;
  background-color: rgb(0, 0, 0);
  border: 3px solid #88888890;
  border-radius: 50%;
}

.user-image {
  border-radius: 50%;
}

.delete-btn {
  margin: 0px 5px;
}
.custom-select-label {
  color: #888888;
  font-size: 0.9em;
  margin-bottom: 5px;
}

.user-form-container {
  // w-100 d-flex flex-column align-items-center align-items-lg-center
  width: 100;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 30px;
  margin-left: 45px;

  .user-form-sizer {
    width: 75%;
    padding: 20px 0px;
  }
}

.header-right-side-container {
  display: flex;
  justify-content: flex-end;
}

.eidt-button-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.user-data-item {
  display: flex;
  width: 100%;
  overflow-x: hidden;
  min-height: 35px;
  height: max-content;
  flex-direction: row;
  border-bottom: 1px dashed #88888850;
  align-items: center;

  .label {
    color: #888888;
    min-width: 120px;
    &::after {
      content: " : ";
    }
  }

  .data-fi {
    color: #fff;
  }
}

@media screen and (max-width: 768px) {
  .user-form-container {
    padding: 0px 15px;
    .user-form-sizer {
      width: 100%;
    }
  }

  .header-right-side-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .user-form-container {
    margin-left: 45px;
    padding: 0px 15px;
    .user-form-sizer {
      width: 90%;
    }
  }
}

.attachment-section-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  button {
    background: none;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #e2c02b;
    padding: 5px 0px;

    svg {
      margin-top: -5px;
      margin-right: 5px;
    }

    &:focus, &:hover {
      background: none;
      color: #e2c02b;
      box-shadow: none;
      border: none;
      outline: none;
    }
  }
}

.gym-attachment-table {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin: 15px 0px;
  .table-body {
    display: flex;
    width: 100%;
    margin: 0;
    padding: 0;
    flex-direction: column;
    & .table-row {
      display: flex;
      width: 100%;
      justify-content: space-between;

      & > span {
        display: flex;
        flex-grow: 1;
        width: 100%;
        border-bottom: 1px solid #88888850;
        font-size: 0.9em;
        color: #888;
        padding-left: 5px;
        overflow-x: hidden;
        align-items: center;
        padding: 5px 0px;
        a {
          padding: 0;
          color: royalblue;
          text-decoration: underline;
          cursor: pointer;

          &:hover {
            color: goldenrod;
          }
        }
      }

      & span:first-child {
        display: inline-flex;
        width: 80px;
      }

      & span:last-child {
        display: inline-flex;
        justify-content: flex-end;
        width: unset;
        min-width: 50px;
        padding-right: 5px;

        button {
          border-radius: 50%;
          padding: 1px 5px;
          margin: 0;
        }
      }
    }
  }
  .table-headings {
    display: flex;
    width: 100%;
    justify-content: space-between;
    & > span {
      display: flex;
      flex-grow: 1;
      width: 100%;
      border-bottom: 1px solid #88888850;
      font-size: 0.9em;
      color: #888;
      padding-left: 5px;
      overflow-x: hidden;
      align-items: center;
      padding: 5px 0px;
      a {
        padding: 0;
      }
    }

    & span:first-child {
      display: inline-flex;
      width: 80px;
    }

    & span:last-child {
      display: inline-flex;
      justify-content: flex-end;
      width: unset;
      min-width: 50px;
      padding-right: 5px;

      button {
        border-radius: 50%;
        padding: 1px 5px;
        margin: 0;
      }
    }
  }
}

.add-attachment-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 350px;

  button {
    text-transform: uppercase;
    font-size: 0.9em;
    letter-spacing: 0.05em;
  }

  .attachment-file-uploader {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 42px;
    border: 1px solid #88888850;
    color: #fff;
    padding: 5px 15px;
    overflow-x: hidden;

    .delete-btn {
      width: max-content !important;
    }

    .error-msg {
      display: block;
      flex: 1;
      width: max-content;
      margin-bottom: 12px;
      margin-top: 8px;
      padding: 0px 10px;
      text-align: center;
      color: rgb(230, 68, 68);
      background-color: rgb(83, 26, 26);
      border-radius: 4px;
      font-size: 0.9em;
    }

    .file-name {
      display: block;
      flex: 1;
      width: 100%;
      margin-bottom: 12px;
      margin-top: 8px;
      text-align: center;
      color: #888;
      min-height: 32px;
    }

    button {
      width: 95%;
    }
  }
}
