.page-header-container {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  min-height: 70px;
  background-color: #000;
  max-height: 70px;
  color: #ebebeb;
  padding: 0px 40px;
}

.page-header-title {
  color: #ebebeb;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 0.05em;
}

.page-header-rightside{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: max-content;

  .inputbox-group{
    margin: 0px;
    margin-right: 5px;
    margin-left: 5px;
  }
}

@media screen and (max-width: 768px) {
  .page-header-container {
    display: contents;
    flex-direction: column;
    min-height: max-content;
    background-color: #1c1a1d;
    align-items: unset;
  }

  .page-header-title {
    line-height: 1;
    padding-top: 20px;
    text-align: center;
  }

  .inputbox-group {
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 15px;
  }

  .page-header-rightside{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  
    .inputbox-group{
      margin: 0px;
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }
}
