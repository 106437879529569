@font-face {
  font-family: "CabinRegular";
  src: local("CarbinRegular"),
    url("./assets/fonts/Cabin/Cabin-Regular.ttf") format("truetype");
  font-weight: normal;
}

body {
  margin: 0;
  font-family: "CabinRegular", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #1c1a1d;
  cursor: default;
  font-size: 16px;
  overflow-x: hidden;
  min-width: 400px;
}

*,
*::before,
*::after {
  box-sizing: initial;
}

@font-face {
  font-family: "CarbinBold";
  src: local("CarbinBold"),
    url("./assets/fonts/Cabin/Cabin-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "CarbinItalic";
  src: local("CarbinItalic"),
    url("./assets/fonts/Cabin/Cabin-Italic.ttf") format("truetype");
}

.modal {
  min-width: 400px;
}

.modal-content {
  background: none;
  width: unset;
}

.modal-dialog-centered {
  justify-content: center;
}

.init-loading {
  display: flex;
  position: relative;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/*
@media (min-width: 1440px) and (max-width: 2400px) {
  body {
    font-size: 16px;
  }
}
@media (min-width: 2400px) and (max-width: 3000px) {
  body {
    font-size: 30px;
  }
}
@media (min-width: 3000px) and (max-width: 4000px){
  body {
    font-size: 40px;
  }
}
@media (min-width: 4000px) and (max-width: 5000px) {
  body {
    font-size: 50px;
  }
}
@media (min-width: 5000px) {
  body {
    font-size: 60px;
  }
}
*/

h5 {
  font-weight: normal;
}

#react-select-3-listbox {
  background-color: #1c1a1d;
  color: #ccc !important;
}

.input-new {
  color: #888888 !important;
  background: #1c1a1d !important;
  border-radius: 8px !important;
  border: 1px solid #888888 !important;
  height: 38px !important;
  padding-left: 10px !important;
  margin-bottom: 15px !important;
}

.nav-tabs .nav-item > .nav-link {
  color: #ffffff;
}

.nav-tabs .nav-item > .nav-link.active {
  color: #000;
  background-color: #fbd806;
  border-color: #fbd806;
}

.tab-pane {
  color: rgb(206, 206, 206);
  /* background-color: #1c1a1d; */
  background-color: #000;
  min-height: 750px;
}

input[type="date"],
input[type="time"] {
  color-scheme: dark;
}
::-webkit-calendar-picker-indicator {
  filter: invert(0);
}
  @media (max-width: 1180px) {
  .iURPOk .content{
        min-height: 100vh;
      }
    }
@media (max-width: 768px) {
  .content .table-content .table-row > * {
    padding-bottom: 10px;
    border-bottom: 1px solid #4f4f4f;
    min-height: 22.9px;
  }
}
