.view-class-container {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  min-width: 450px;
  background-color: #000;
  color: #888;
  height: max-content;

  .cRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 5px 0px;
  }

  .colHeader {
    position: relative;
    border-bottom: 1px solid #88888840;
    width: 35%;
    &::after {
      content: ":";
      position: absolute;
      right: 10px;
      color: #fff;
    }
  }
  .colData {
    border-bottom: 1px solid #88888840;
    width: 65%;
    color: #bfbfbf;

    &.trainers {
      padding: 0px 0px 5px 0px;
      span {
        padding: 3px 8px;
        background-color: #c7ad3a;
        color: #000;
        margin: 3px 0px;
        margin-right: 0px;
        border-radius: 20px;
        font-size: 0.8em;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .view-class-container {
    min-width: 90vw;
    padding: 5px;
    .cRow {
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
    }

    .colHeader {
      position: relative;
      border-width: 0px;
      color: #888;
      font-size: 0.9em;
      width: 100%;
      &::after {
        content: none;
      }
    }
    .colData {
      border-bottom: 1px solid #88888840;
      width: 100%;
    }
  }
}
