.inputbox-group {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 8px;

  &.spaceTop {
    margin-top: 10px;
  }

  & label {
    margin-bottom: 3px;
    color: #9c9c9c;
    font-size: 0.9rem;
  }

  & .inputbox-icon {
    display: inline-block;
    width: max-content;
    min-width: 25px;
    max-height: 25px;
    position: absolute;
    padding: 10px 0px 0px 12px;
    color: #888888;
  }

  & input, textarea {
    background: #1c1a1d;
    border: 1px solid #888888;
    border-radius: 6px;
    height: 40px;
    color: #888888;
    min-width: 120px !important;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 0px 10px;
    &:focus {
      outline: none;
      border: 1px solid rgba($color: #fff, $alpha: 0.8);
      background: #242225;
    }

    &.disabled {
      border: 1px solid #88888855 !important;
      pointer-events: none;
      background: #1c1a1d !important;
      color: #88888890;
      &:focus {
        outline: none;
        border: 1px solid #88888855 !important;
        background: #1c1a1d !important;
      }
    }
  }

  & textarea {
    height: 65px;
    padding: 8px 8px;
  }

  & input[type="file"] {
    display: none;
  }
  .phone-input {
    background-color: #1c1a1d;
    color: #888888;
    width: 100%;
    border: none;
    border: 1px solid #888;
    border-radius: 8px;
    height: 42px;
    &:hover,
    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }    
  }
  .phone-container {
    color: #888888;
    background: #1c1a1d;
    border-radius: 12px;
    border: 0px solid #888888;
    height: 50px;
    padding-left: 10px;
    margin-bottom: 20px;
    &:hover,
    &:active,
    &:focus {
      outline: none;
    }
  }
  .phone-button {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border: none;
    background: #1c1a1d;
    margin: 1px;

    &:hover,
    &:active,
    &:focus {
      outline: none;
      background: #1c1a1d;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }
  .phone-button.open {
    background: #1c1a1d;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .react-tel-input{
    border: 1px solid transparent;
    border-radius: 8px;
    &.inputbox-error {
     & > button, & > input{
        border: none;
      }
      border: 2px solid rgba($color: rgb(187, 17, 17), $alpha: 0.8) !important;
      border-radius: 8px;
    }
  }
  .react-tel-input .flag-dropdown.open {
    background: #1c1a1d;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .react-tel-input .selected-flag:hover,
  .react-tel-input .selected-flag:focus {
    background: #1c1a1d;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .react-tel-input .flag-dropdown.open .selected-flag {
    background: #1c1a1d;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .phone-dropdown {
    background: #1c1a1d;
    color: white;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background-color: #888888;
      border-radius: 100px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      background-color: #ffcc00;
    }
  }
  .react-tel-input .country-list .country:hover {
    background-color: #302d2d;
  }
  .react-tel-input .country-list .country.highlight {
    background-color: #424141;
  }
}

.inputbox-error {
  border: 1px solid rgba($color: rgb(187, 17, 17), $alpha: 0.8) !important;
  border-radius: 8px;
}
